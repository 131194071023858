import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";

import PrivacyPolicy from "./SakuLegal/PrivacyPolicy";
import UserAndMerchantTerms from "./SakuLegal/UserAndMerchantTerms";
import JoinSaku from "./SignUp/JoinSaku";
import PaymentSuccess from "./PaymentResult/PaymentSucess";
import PaymentFail from "./PaymentResult/PaymentFail";
import PaymentPending from "./PaymentResult/PaymentPending";
import FAQ from "./FAQ/FAQ";
import SakuHome from "./HomeSolutionAboutUs/SakuHome";
import SakuSolution from "./HomeSolutionAboutUs/SakuSolution";
import SakuAboutUs from "./HomeSolutionAboutUs/SakuAboutUs";
import Price from "./Price/Price";
import Blog from "./Blog/Blog";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

// Access the reCAPTCHA site key from environment variables
const recaptchaKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;

const AnimatedRoutes = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Routes location={location}>
      <Route path="/" element={<SakuHome />} />
      <Route path="/Home" element={<SakuHome />} />
      <Route path="/Solution" element={<SakuSolution />} />
      <Route path="/AboutUs" element={<SakuAboutUs />} />
      <Route path="/Price" element={<Price />} />
      <Route
        path="/JoinSaku"
        element={
          <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
            <JoinSaku />
            <hr />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Box
                component="img"
                src="./assets/Saku_SignUp.webp"
                alt="Sign Up"
                sx={{
                  maxWidth: isMobile ? "80%" : "50%",
                  height: "auto",
                }}
              />
            </Box>
            <br />
          </GoogleReCaptchaProvider>
        }
      />
      <Route path="/PaymentSuccess" element={<PaymentSuccess />} />
      <Route path="/PaymentFail" element={<PaymentFail />} />
      <Route path="/PaymentPending" element={<PaymentPending />} />
      <Route path="/Privacy-Policy" element={<PrivacyPolicy />} />
      <Route path="/User-Terms" element={<UserAndMerchantTerms />} />
      <Route path="/Merchant-Terms" element={<UserAndMerchantTerms />} />
      <Route path="/UserAndMerchantTerms" element={<UserAndMerchantTerms />} />
      <Route path="/FAQ" element={<FAQ />} />
      <Route path="/UserFAQ" element={<FAQ />} />
      <Route path="/MerchantFAQ" element={<FAQ />} />
      <Route path="/Blog" element={<Blog />} />
    </Routes>
  );
};

export default AnimatedRoutes;
