import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import { ColorlibConnector, ColorlibStepIcon } from "./CustomStepper";
import Swal from "sweetalert2";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Info from "./Info";
import InfoMobile from "./InfoMobile";
import Payment from "./Payment";
import ClientInfo from "./ClientInfo";
import axios from "axios"; // Import axios for API requests
import { useLocation } from "react-router-dom";

const steps = ["1. Company Info", "2. Payment"];

const REACT_APP_SAKU_WEBSITEAPI_CHECKREFERRALCODEEXISTURL =
  process.env.REACT_APP_SAKU_WEBSITEAPI_CHECKREFERRALCODEEXISTURL;
const REACT_APP_SAKU_WEBSITEAPI_APIKEY = process.env.REACT_APP_SAKU_WEBSITEAPI_APIKEY;
const REACT_APP_SAKU_WEBSITEAPI_GETCLIENTPACKAGELISTFORCREATECLIENTURL =
  process.env.REACT_APP_SAKU_WEBSITEAPI_GETCLIENTPACKAGELISTFORCREATECLIENTURL;

const getQueryParamIgnoreCase = (params, key) => {
  const lowerKey = key.toLowerCase();
  for (const [k, v] of params.entries()) {
    if (k.toLowerCase() === lowerKey) {
      return v;
    }
  }
  return null;
};

export default function SignUp() {
  const [activeStep, setActiveStep] = React.useState(1);
  const [totalPrice, setTotalPrice] = useState(0.0);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const clientPackageIDFromQuery = getQueryParamIgnoreCase(queryParams, "clientPackageID");
  const referralCodeFromQuery = getQueryParamIgnoreCase(queryParams, "referralCode");
  const [clientPackageList, setClientPackageList] = useState([]);
  const [clientPackage, setClientPackage] = useState(null);
  const [loading, setLoading] = React.useState(true);

  const [formData, setFormData] = useState({
    companyName: "",
    brandName: "",
    customerName: "",
    customerPhone: "",
    customerEmail: "",
    referralCode: "",
    totalOutlet: 1,
    totalAmount: 0.0,
    clientPackageID: null,
    agreeToTerms: false,
  });

  useEffect(() => {
    Swal.fire({
      title: "Loading...",
      text: "Please wait while we fetch the data.",
      color: "#716add",
      backdrop: `rgba(135, 206, 250, 0.4) left top no-repeat`, // Sky blue backdrop
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (referralCodeFromQuery) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        referralCode: referralCodeFromQuery.toUpperCase().slice(0, 6),
      }));
    }

    axios
      .post(
        REACT_APP_SAKU_WEBSITEAPI_GETCLIENTPACKAGELISTFORCREATECLIENTURL,
        {}, // Request body, if needed, otherwise an empty object
        {
          headers: {
            "Authorization-Key": REACT_APP_SAKU_WEBSITEAPI_APIKEY,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const packages = response.data.responseData.clientPackageList;
        setClientPackageList(packages);
        //console.log(packages);

        let selectedPackage;

        // Check if the clientPackageID from the query string exists in the fetched package list
        if (clientPackageIDFromQuery) {
          selectedPackage = packages.find(
            (pkg) => pkg.clientPackageID === clientPackageIDFromQuery
          );
        }

        // If the package with the query string ID doesn't exist or no query string ID provided
        if (!selectedPackage) {
          selectedPackage = packages.find((pkg) => pkg.isRecommended === "1");
        }

        // If no recommended package exists, take the first one in the list
        if (!selectedPackage && packages.length > 0) {
          selectedPackage = packages[0];
        }

        if (selectedPackage) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            clientPackageID: selectedPackage.clientPackageID,
          }));
          setClientPackage(selectedPackage);
        }
        setLoading(false);
        Swal.close(); // Close the SweetAlert2 loader
        //console.log(selectedPackage);
        //console.log(formData);
      })
      .catch((error) => {
        //console.error("There was an error fetching the data!", error);
        setLoading(false);
        Swal.close(); // Close the SweetAlert2 loader
      });
  }, [clientPackageIDFromQuery, referralCodeFromQuery]);

  const handleTotalPriceChange = (newTotalPrice) => {
    setTotalPrice(newTotalPrice);
    formData.totalAmount = newTotalPrice;
  };

  const [errors, setErrors] = useState({});

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));

    validateField(name, checked);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Ensure the value is within the allowed range
    if (name === "totalOutlet") {
      const numberValue = parseInt(value, 10);
      // if (!value || isNaN(numberValue)) {
      //   setFormData((prevData) => ({
      //     ...prevData,
      //     [name]: 1,
      //   }));
      // } else if (numberValue < 1) {
      //   setFormData((prevData) => ({
      //     ...prevData,
      //     [name]: 1,
      //   }));
      // } else if (numberValue > 20) {
      //   setFormData((prevData) => ({
      //     ...prevData,
      //     [name]: 20,
      //   }));
      // } else {
      //   setFormData((prevData) => ({
      //     ...prevData,
      //     [name]: numberValue,
      //   }));
      // }
      setFormData((prevData) => ({
        ...prevData,
        [name]: numberValue,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    validateField(name, value);
  };

  const capitalizeWords = (str) => {
    return str
      .replace(/([A-Z])/g, " $1")
      .trim()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const validateField = async (name, value) => {
    let error = "";
    const transformedName = capitalizeWords(name);

    switch (name) {
      case "companyName":
      case "brandName":
      case "customerName":
        error = validateLength(value, transformedName, 1, 50);
        break;
      case "referralCode":
        error = await validateReferralCode(value, transformedName);
        break;
      case "customerPhone":
        error = validatePhone(value, transformedName);
        break;
      case "customerEmail":
        error = validateEmail(value, transformedName);
        break;
      case "totalOutlet":
        error = validateNumberRange(value, transformedName, 1, 20);
        break;
      case "agreeToTerms":
        error = value
          ? ""
          : "You must agree to the terms and conditions and privacy policy.";
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const validateLength = (value, fieldName, minLength, maxLength) => {
    if (!value) return `${fieldName} is required`;
    if (value.length < minLength)
      return `${fieldName} must be at least ${minLength} characters`;
    if (value.length > maxLength)
      return `${fieldName} must be less than ${maxLength} characters`;
    return "";
  };

  const validateEmail = (email, fieldName) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) return `${fieldName} is required`;
    if (!emailRegex.test(email)) return `${fieldName} is not valid`;
    return "";
  };

  const validatePhone = (phone, fieldName) => {
    if (!phone) return `${fieldName} is required`;
    if (phone.length < 10 || phone.length > 16)
      return `${fieldName} must be between 10 and 16 digits`;
    return "";
  };

  const validateNumberRange = (value, fieldName, min, max) => {
    const numberValue = parseInt(value, 10);
    if (isNaN(numberValue)) return `${fieldName} must be a number`;
    if (numberValue < min || numberValue > max)
      return `${fieldName} must be between ${min} and ${max}`;
    return "";
  };

  const validateReferralCode = async (value, fieldName) => {
    if (value && value.length !== 6) {
      return `${fieldName} must be exactly 6 characters`;
    }
    if (value && value.length === 6) {
      try {
        const response = await axios.post(
          REACT_APP_SAKU_WEBSITEAPI_CHECKREFERRALCODEEXISTURL,
          { referralCode: value },
          {
            headers: {
              "Authorization-Key": REACT_APP_SAKU_WEBSITEAPI_APIKEY, // Replace with your actual key
              "Content-Type": "application/json",
            },
          }
        );
        //console.log(response.data);
        if (response.data.responseCode === "00") {
          return ""; // No error message, referral code is valid
        } else if (response.data.responseCode === "56") {
          return `${fieldName} is invalid or does not exist in our records.`;
        } else {
          return `An unexpected error occurred. Please try again.`;
        }
      } catch (error) {
        return `Unable to validate ${fieldName}. Please check your connection and try again.`;
      }
    }
    return "";
  };

  const validateStep1 = () => {
    const requiredFieldsStep1 = [
      "companyName",
      "brandName",
      "customerName",
      "customerPhone",
      "customerEmail",
      "referralCode",
      "totalOutlet",
      "agreeToTerms",
    ];

    let isValid = true;
    requiredFieldsStep1.forEach((field) => {
      if (field !== "referralCode") {
        // For required fields, check if they are present and if there are any errors
        if (!formData[field] || errors[field]) {
          isValid = false;
          validateField(field, formData[field]); // Set the error message if it's not already set
        }
      } else {
        // For referralCode, only validate if it's provided
        if (formData[field]) {
          validateField(field, formData[field]);
          if (errors[field]) {
            isValid = false;
          }
        }
      }
    });

    return isValid;
  };

  const handleNext = () => {
    //console.log(formData);
    if (activeStep === 1) {
      if (validateStep1()) {
        // Clean up the phone number format
        formData.customerPhone = formData.customerPhone.replace(/[+\s]/g, "");
        setActiveStep(activeStep + 1);
        //console.log(formData);
      } else {
        Swal.fire({
          icon: "error",
          title: "Incomplete Information",
          text: "Please fill out all required fields in the Company Info section before proceeding.",
          confirmButtonText: "Got it",
        });
      }
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 1:
        return (
          <ClientInfo
            formData={formData}
            handleInputChange={handleInputChange}
            errors={errors}
            handleCheckboxChange={handleCheckboxChange}
          />
        );
      case 2:
        return <Payment formData={formData} />;
      default:
        return <div>Unknown Step Number</div>;
    }
  };

  return (
    <Grid container sx={{ height: { xs: "100%", sm: "100%" } }}>
      <Grid
        item
        xs={12}
        sm={5}
        lg={3}
        sx={{
          display: { xs: "none", md: "flex" },
          flexDirection: "column",
          backgroundColor: "background.paper",
          borderRight: { sm: "none", md: "1px solid" },
          borderColor: { sm: "none", md: "divider" },
          alignItems: "start",
          pt: 4,
          px: 5,
          gap: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            width: "100%",
            maxWidth: 500,
          }}
        >
          <Info
            totalOutlet={formData.totalOutlet}
            onTotalPriceChange={handleTotalPriceChange}
            selectedClientPackage={clientPackage}
          />
        </Box>
      </Grid>
      <Grid
        item
        sm={12}
        md={7}
        lg={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "100%",
          width: "100%",
          backgroundColor: { xs: "transparent", sm: "background.default" },
          alignItems: "start",
          pt: { xs: 2, sm: 4 },
          px: { xs: 2, sm: 5 },
          gap: { xs: 4, md: 8 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: { sm: "space-between", md: "flex-end" },
            alignItems: "center",
            width: "100%",
            maxWidth: { sm: "100%", md: "100%" },
          }}
        >
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "flex-end",
              flexGrow: 1,
              height: 1 - 0,
            }}
          >
            <Stepper
              id="desktop-stepper"
              activeStep={activeStep - 1}
              sx={{
                width: "100%",
                height: 60,
              }}
              alternativeLabel
              connector={<ColorlibConnector />}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    <Typography
                      variant="h5"
                      color="var(--primary-color-1)"
                      style={{ fontWeight: "bold" }}
                    >
                      {label}
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Box>
        <Card
          sx={{
            display: { xs: "flex", md: "none" },
            width: "100%",
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
              ":last-child": { pb: 1 },
            }}
          >
            <Typography variant="h6" color="text.black">
              Total: RM {totalPrice?.toFixed(2)}
            </Typography>
            <InfoMobile
              totalOutlet={formData.totalOutlet}
              handleTotalPriceChange={handleTotalPriceChange}
              selectedClientPackage={clientPackage}
            />
          </CardContent>
        </Card>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            width: "100%",
            maxWidth: { sm: "100%", md: "100%" },
            gap: { xs: 5, md: "none" },
          }}
        >
          <Stepper
            id="mobile-stepper"
            activeStep={activeStep - 1}
            alternativeLabel
            connector={<ColorlibConnector />}
            sx={{ display: { sm: "flex", md: "none" } }}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel
                  sx={{
                    ".MuiStepLabel-labelContainer": { maxWidth: "70px" },
                  }}
                  StepIconComponent={ColorlibStepIcon}
                >
                  <Typography
                    variant="h6"
                    color="var(--primary-color-1)"
                    style={{ fontWeight: "bold" }}
                  >
                    {label}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {getStepContent(activeStep)}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column-reverse", sm: "row" },
                justifyContent: activeStep !== 1 ? "space-between" : "flex-end",
                alignItems: "end",
                flexGrow: 1,
                gap: 1,
                pb: { xs: 12, sm: 0 },
                mt: { xs: 2, sm: 0 },
                mb: "30px",
              }}
            >
              {activeStep !== 1 && (
                <Button
                  startIcon={<ChevronLeftRoundedIcon />}
                  onClick={handleBack}
                  variant="text"
                  sx={{
                    display: { xs: "none", sm: "flex" },
                  }}
                >
                  Previous
                </Button>
              )}

              {activeStep !== 1 && (
                <Button
                  startIcon={<ChevronLeftRoundedIcon />}
                  onClick={handleBack}
                  variant="outlined"
                  fullWidth
                  sx={{
                    display: { xs: "flex", sm: "none" },
                  }}
                >
                  Previous
                </Button>
              )}
              {activeStep !== steps.length && (
                <Button
                  variant="contained"
                  endIcon={<ChevronRightRoundedIcon />}
                  onClick={handleNext}
                  sx={{
                    width: { xs: "100%", sm: "fit-content" },
                  }}
                >
                  {"Next"}
                </Button>
              )}
            </Box>
          </React.Fragment>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={5}
        lg={3}
        sx={{
          display: { xs: "none", md: "flex" },
          flexDirection: "column",
          backgroundColor: "background.paper",
          alignItems: "stretch", // Adjusted to stretch the items
          pt: 4,
          px: 4,
          gap: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column", // Stack images vertically
            justifyContent: "flex-start",
            alignItems: "stretch", // Stretch images to fill the container's width
            height: "100%", // Ensure the container height allows images to be displayed
          }}
        >
          <br />
          <Box
            component="img"
            src="./assets/JoinSakuNow2.webp"
            alt="Sign Up"
            sx={{
              width: "100%", // Full width
              height: "auto", // Maintain aspect ratio
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
