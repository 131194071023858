import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import { Link } from "react-router-dom";
import axios from "axios"; // Import axios
import Swal from "sweetalert2";

const REACT_APP_SAKU_WEBSITEAPI_GETCLIENTPACKAGELISTURL =
  process.env.REACT_APP_SAKU_WEBSITEAPI_GETCLIENTPACKAGELISTURL;
const REACT_APP_SAKU_WEBSITEAPI_APIKEY =
  process.env.REACT_APP_SAKU_WEBSITEAPI_APIKEY;

export default function Price() {
  const [clientPackage, setClientPackage] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    Swal.fire({
      title: "Loading...",
      text: "Please wait while we fetch the data.",
      color: "#716add",
      backdrop: `rgba(135, 206, 250, 0.4) left top no-repeat`, // Sky blue backdrop
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    axios
      .post(
        REACT_APP_SAKU_WEBSITEAPI_GETCLIENTPACKAGELISTURL,
        {}, // Request body, if needed, otherwise an empty object
        {
          headers: {
            "Authorization-Key": REACT_APP_SAKU_WEBSITEAPI_APIKEY,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // Assuming your API returns the data in the same format as the hardcoded clientPackage
        setClientPackage(response.data.responseData.clientPackageList);
        setLoading(false);
        Swal.close(); // Close the SweetAlert2 loader
      })
      .catch((error) => {
        //console.error("There was an error fetching the data!", error);
        setLoading(false);
        Swal.close(); // Close the SweetAlert2 loader
      });
  }, []);

  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 6 },
        pb: { xs: 8, sm: 6 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography component="h2" variant="h4">
          Plans
        </Typography>
        <Typography variant="body1">
          Choose the perfect plan for your loyalty needs. Whether you're a small
          business, a growing brand, or an enterprise with unique requirements,
          we’ve got you covered.
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems="stretch" justifyContent="center">
        {clientPackage.map((tier) => (
          <Grid item key={tier.clientPackageID} xs={12} sm={6} md={4}>
            <Card
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                gap: 4,
                background:
                  tier.isRecommended === "1"
                    ? "var(--primary-color-1)"
                    : undefined,
                minHeight: "100%", // Ensure cards stretch to equal height
                justifyContent: "space-between",
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: tier.isRecommended === "1" ? "grey.100" : "",
                  }}
                >
                  <Typography
                    component="h3"
                    variant="h5"
                    sx={{ fontWeight: "bold" }}
                  >
                    {tier.packageName}
                  </Typography>

                  {tier.isRecommended === "1" && (
                    <Chip
                      icon={<AutoAwesomeIcon />}
                      label="Recommended"
                      size="small"
                      sx={{
                        background: (theme) =>
                          theme.palette.mode === "light" ? "" : "none",
                        backgroundColor: "primary.contrastText",
                        "& .MuiChip-label": {
                          color: "primary.dark",
                        },
                        "& .MuiChip-icon": {
                          color: "primary.dark",
                        },
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    color: tier.isRecommended === "1" ? "grey.50" : undefined,
                  }}
                >
                  {tier.showJoinSakuButton === "1" ? (
                    <Typography component="h3" variant="h4">
                      <p style={{ fontSize: "0.6em" }}>Start from</p>
                      <p style={{ paddingTop: "2px", paddingBottom: "2px" }}>
                        RM{(parseFloat(tier.packagePrice) || 0).toFixed(0)}
                      </p>
                      <p style={{ fontSize: "0.6em" }}>
                        for {tier.packagePriceDuration}{" "}
                        {tier.packagePriceType?.toLowerCase()}
                      </p>
                    </Typography>
                  ) : (
                    <Typography component="h3" variant="h4">
                      Talk to Sales
                    </Typography>
                  )}
                </Box>
                <Divider
                  sx={{
                    my: 1,
                    opacity: 0.2,
                    borderColor:
                      tier.isRecommended === "1" ? "black" : "grey.500",
                  }}
                />
                {(tier.description || "").split("\n").map((item, index) => {
                  const trimmedItem = item.trim();
                  const isFirstLevel = trimmedItem.startsWith("-");
                  const isSecondLevel = trimmedItem.startsWith("*");
                  const content = trimmedItem.replace(/^[-*]\s*/, ""); // Remove '-' or '*' for display

                  return (
                    <Box
                      key={index}
                      sx={{
                        py: 1,
                        display: "flex",
                        gap: 1.5,
                        alignItems: "center",
                        pl: isSecondLevel ? 3 : 0, // Add padding for second-level items
                      }}
                    >
                      {isFirstLevel && (
                        <CheckCircleRoundedIcon
                          sx={{
                            width: 20,
                            color:
                              tier.isRecommended === "1"
                                ? "var(--secondary-color-3)"
                                : "var(--primary-color-1)",
                          }}
                        />
                      )}
                      {isSecondLevel && (
                        <StarRateRoundedIcon
                          sx={{
                            width: 16,
                            color:
                              tier.isRecommended === "1"
                                ? "var(--secondary-color-3)"
                                : "var(--primary-color-1)",
                          }}
                        />
                      )}
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color:
                            tier.isRecommended === "1"
                              ? "var(--tertiary-color-1)"
                              : undefined,
                        }}
                      >
                        {content}
                      </Typography>
                    </Box>
                  );
                })}
              </CardContent>
              <CardActions>
                {tier.showJoinSakuButton === "1" ? (
                  <Button
                    fullWidth
                    variant="contained"
                    component={Link}
                    to={`/JoinSaku?clientPackageID=${tier.clientPackageID}`}
                    style={{
                      backgroundColor: "var(--secondary-color-3)",
                    }}
                  >
                    Sign Up Now
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    variant="outlined"
                    component={Link}
                    to="https://us.bigin.online/org866370276/forms/sakurewards"
                    style={{
                      borderColor: "var(--secondary-color-3)",
                      color: "var(--secondary-color-3)",
                    }}
                  >
                    Contact Us
                  </Button>
                )}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      {clientPackage && clientPackage.length > 0 && (
        <Grid
          container
          spacing={3}
          alignItems="stretch"
          justifyContent="center"
        >
          <section className="text-gray-700 body-font overflow-hidden border-gray-200">
            <div className="container px-5 py-24 mx-auto flex flex-wrap">
              <Box
                sx={{
                  width: { sm: "100%", md: "100%" },
                  textAlign: { sm: "center", md: "center" },
                  paddingBottom: "20px",
                }}
              >
                <Typography component="h2" variant="h4">
                  Compare Plans & Features
                </Typography>
              </Box>

              {/* Horizontal scrolling for mobile layout */}
              <div className="flex w-full overflow-x-auto mt-6">
                <div className="mt-48 flex-shrink-0">
                  <div className="mt-px border-t border-gray-300 border-b border-l rounded-tl-lg rounded-bl-lg overflow-hidden">
                    {clientPackage?.[0]?.featureComparison ? (
                      (() => {
                        try {
                          // Parse featureComparison if it's a string
                          const featureComparison =
                            typeof clientPackage[0].featureComparison ===
                            "string"
                              ? JSON.parse(clientPackage[0].featureComparison)
                              : clientPackage[0].featureComparison;

                          return Object.entries(featureComparison).map(
                            ([feature, value], index) => (
                              <p
                                key={feature}
                                className={`h-12 text-center px-4 flex items-center justify-start ${
                                  index % 2 === 0
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-900"
                                }`}
                              >
                                <span className="font-medium">{feature}</span>
                              </p>
                            )
                          );
                        } catch (error) {
                          console.error(
                            "Error parsing featureComparison JSON:",
                            error
                          );
                          return (
                            <></>
                            // <p className="text-gray-900 h-12 text-center px-4 flex items-center justify-start">
                            //   Invalid feature data.
                            // </p>
                          );
                        }
                      })()
                    ) : (
                      <p className="text-gray-900 h-12 text-center px-4 flex items-center justify-start">
                        No features available.
                      </p>
                    )}
                  </div>
                </div>

                {clientPackage.map((tier) => (
                  <div
                    key={tier.clientPackageID}
                    className={`flex-shrink-0 flex-1 mb-10 lg:mb-0 rounded-lg relative ${
                      tier.isRecommended === "1"
                        ? "lg:border lg:rounded"
                        : "border-gray-300 lg:border lg:rounded"
                    }`}
                    style={{
                      minWidth: "250px",
                      maxWidth: "300px", // Set min and max width for consistency
                      borderWidth: tier.isRecommended === "1" ? "3px" : "1px",
                      borderColor:
                        tier.isRecommended === "1"
                          ? "var(--primary-color-1)"
                          : "",
                    }}
                  >
                    {tier.isRecommended === "1" && (
                      <span
                        className="text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl"
                        style={{
                          backgroundColor:
                            tier.isRecommended === "1"
                              ? "var(--primary-color-1)"
                              : "",
                        }}
                      >
                        Recommended
                      </span>
                    )}

                    <div className="px-2 text-center h-48 flex flex-col items-center justify-center border-b border-gray-300">
                      <h2 className="text-5xl text-gray-900 font-medium leading-none mb-4 mt-2">
                        {tier.packageName}
                      </h2>
                      {tier.showJoinSakuButton === "1" ? (
                        <span className="text-gray-600">
                          Start from RM{" "}
                          {(parseFloat(tier.packagePrice) || 0).toFixed(0)}
                          &nbsp;for {tier.packagePriceDuration}{" "}
                          {tier.packagePriceType?.toLowerCase()}
                        </span>
                      ) : (
                        <span className="text-gray-600">Talk to Sales</span>
                      )}
                    </div>

                    {tier.featureComparison ? (
                      (() => {
                        try {
                          // Parse featureComparison for the first package if it's a string
                          const firstPackageFeatures = clientPackage?.[0]
                            ?.featureComparison
                            ? typeof clientPackage[0].featureComparison ===
                              "string"
                              ? JSON.parse(clientPackage[0].featureComparison)
                              : clientPackage[0].featureComparison
                            : {};

                          // Parse featureComparison for the current tier
                          const featureComparison =
                            typeof tier.featureComparison === "string"
                              ? JSON.parse(tier.featureComparison)
                              : tier.featureComparison;

                          // Order features based on the first package
                          const orderedFeatures = Object.keys(
                            firstPackageFeatures
                          ).map((key) => ({
                            feature: key,
                            value: featureComparison[key],
                          }));

                          // Render the ordered features
                          return orderedFeatures.map(
                            ({ feature, value }, index) => (
                              <p
                                key={feature}
                                className={`h-12 text-center leading-relaxed flex items-center justify-center ${
                                  index % 2 === 0
                                    ? "bg-gray-100 text-gray-600"
                                    : "text-gray-600"
                                }`}
                              >
                                {value &&
                                value.toString().toLowerCase() === "yes" ? (
                                  <svg
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="3"
                                    className="w-6 h-6 text-green-500"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M20 6L9 17l-5-5"></path>
                                  </svg>
                                ) : value &&
                                  value.toString().toLowerCase() === "no" ? (
                                  <svg
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2.5"
                                    className="w-6 h-6 text-red-500"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M18 6L6 18M6 6l12 12"></path>
                                  </svg>
                                ) : (
                                  <span>{value || "N/A"}</span>
                                )}
                              </p>
                            )
                          );
                        } catch (error) {
                          console.error(
                            "Error parsing featureComparison JSON:",
                            error
                          );
                          return (
                            <></>
                            // <p className="text-gray-600 h-12 text-center leading-relaxed flex items-center justify-center">
                            //   Invalid feature data.
                            // </p>
                          );
                        }
                      })()
                    ) : (
                      <p className="text-gray-600 h-12 text-center leading-relaxed flex items-center justify-center">
                        No features available.
                      </p>
                    )}

                    <div className="p-6 text-center border-t border-gray-300">
                      {tier.showJoinSakuButton === "1" ? (
                        <Button
                          fullWidth
                          variant="contained"
                          component={Link}
                          to={`/JoinSaku?clientPackageID=${tier.clientPackageID}`}
                          style={{
                            backgroundColor: "var(--secondary-color-3)",
                          }}
                        >
                          Sign Up Now
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          variant="outlined"
                          component={Link}
                          to="https://us.bigin.online/org866370276/forms/sakurewards"
                          style={{
                            borderColor: "var(--secondary-color-3)",
                            color: "var(--secondary-color-3)",
                          }}
                        >
                          Contact Us
                        </Button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </Grid>
      )}
    </Container>
  );
}
